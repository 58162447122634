<template>
    <!-- 机器人列表 -->
    <div class="robotBox" :class="{robotBox_phone:isMobile==1}">
        <div class="robotTab">
            <!-- cex -->
            <template v-if="web_type==0">
                <div class="g_btn" :class="{active:robotTabCex==0}" @click="selectTab(0)">{{$t('robotLevel[0]')}}</div>
                <div class="g_btn" :class="{active:robotTabCex==1}" @click="selectTab(1)">{{$t('robotLevel[1]')}}</div>
                <div class="g_btn" :class="{active:robotTabCex==2}" @click="selectTab(2)">{{$t('robotLevel[2]')}}</div>
            </template>
            <!-- dex -->
            <template v-else-if="web_type==1">
                <div class="active">{{$t('level[3]')}}</div>
            </template>
            <!-- nft -->
            <template v-else>
                <div class="active">{{$t('level[4]')}}</div>
            </template>
        </div>
        <!-- cex -->
        <template v-if="web_type==0">
            <div class="robotList" v-show="robotTabCex==0">
                <div class="item" v-for="item in cexRobotList1Sort" :key="item.robotType" :id="item.robotType">
                    <div class="robotTitle">
                        <span>{{$t('robot.list.'+item.robotType+'.name')}}</span>
                        <span class="description" @click="openTip(item.robotType)">
                            <i class="iconfont icon-tips"></i>
                            {{$t('common.description')}}
                        </span>
                    </div>
                    <robotForm :ref="item.robotType" :robotType="item.robotType"
                        @handleConfirm="handleConfirm(item.robotType,arguments)"
                        @handleStop="handleStop(item.robotType,arguments)" 
                        @handleOnlySave="handleOnlySave(item.robotType,arguments)" 
                        @openPop="openPop(item.robotType,arguments)"
                        :web="web" :yenUnit1Old="yenUnit1" :yenUnit2Old="yenUnit2" :isStartOrStop="true"></robotForm>
                </div>
            </div>
            <div class="robotList" v-show="robotTabCex==1">
                <div class="item" v-for="item in cexRobotList2Sort" :key="item.robotType" :id="item.robotType">
                    <div class="robotTitle">
                        <span>{{$t('robot.list.'+item.robotType+'.name')}}</span>
                        <span class="description" @click="openTip(item.robotType)">
                            <i class="iconfont icon-tips"></i>
                            {{$t('common.description')}}
                        </span>
                    </div>
                    <robotForm :ref="item.robotType" :robotType="item.robotType"
                        @handleConfirm="handleConfirm(item.robotType,arguments)"
                        @handleStop="handleStop(item.robotType,arguments)" 
                        @handleOnlySave="handleOnlySave(item.robotType,arguments)" @openPop="openPop(item.robotType,arguments)"
                        :web="web" :yenUnit1Old="yenUnit1" :yenUnit2Old="yenUnit2" :isStartOrStop="true"></robotForm>
                </div>
            </div>
            <div class="robotList" v-show="robotTabCex==2">
                <div class="item" v-for="item in cexRobotList4Sort" :key="item.robotType" :id="item.robotType">
                    <div class="robotTitle">
                        <span>{{$t('robot.list.'+item.robotType+'.name')}}</span>
                        <span class="description" @click="openTip(item.robotType)">
                            <i class="iconfont icon-tips"></i>
                            {{$t('common.description')}}
                        </span>
                    </div>
                    <robotForm :ref="item.robotType" :robotType="item.robotType"
                        @handleConfirm="handleConfirm(item.robotType,arguments)"
                        @handleStop="handleStop(item.robotType,arguments)" 
                        @handleOnlySave="handleOnlySave(item.robotType,arguments)" @openPop="openPop(item.robotType,arguments)"
                        :web="web" :yenUnit1Old="yenUnit1" :yenUnit2Old="yenUnit2" :isStartOrStop="true"></robotForm>
                </div>
            </div>
        </template>
        <!-- dex -->
        <div class="robotList" v-else-if="web_type==1">
            <div class="item" v-for="item in robotListDexSort" :key="item.robotType" :id="item.robotType">
                <div class="robotTitle">
                    <span>{{$t('robot.list.'+item.robotType+'.name')}}</span>
                    <span class="description" @click="openTip(item.robotType)">
                        <i class="iconfont icon-tips"></i>
                        {{$t('common.description')}}
                    </span>
                </div>
                <robotForm :ref="item.robotType" :robotType="item.robotType"
                    @handleConfirm="handleConfirm(item.robotType,arguments)"
                    @handleStop="handleStop(item.robotType,arguments)" 
                    @handleOnlySave="handleOnlySave(item.robotType,arguments)" @openPop="openPop(item.robotType,arguments)"
                    :web="web" :yenUnit1Old="yenUnit1" :yenUnit2Old="yenUnit2" :isStartOrStop="true"></robotForm>
            </div>
        </div>
        <!-- nft -->
        <div class="robotList" v-else-if="web_type==2">
            <div class="item" v-for="item in robotListNftSort" :key="item.robotType" :id="item.robotType">
                <div class="robotTitle">
                    <span>{{$t('robot.list.'+item.robotType+'.name')}}</span>
                    <span class="description" @click="openTip(item.robotType)">
                        <i class="iconfont icon-tips"></i>
                        {{$t('common.description')}}
                    </span>
                </div>
                <robotForm :ref="item.robotType" :robotType="item.robotType"
                    @handleConfirm="handleConfirm(item.robotType,arguments)"
                    @handleStop="handleStop(item.robotType,arguments)" 
                    @handleOnlySave="handleOnlySave(item.robotType,arguments)" @openPop="openPop(item.robotType,arguments)"
                    :web="web" :yenUnit1Old="yenUnit1" :yenUnit2Old="yenUnit2" :isStartOrStop="true"></robotForm>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
	import { Loading } from 'element-ui';

    import typeFormat from '@/utils/methApi/typeFormat';
    import scientificNotationFormat from '@/utils/methApi/scientificNotationFormat'
    // api
    import { setRobot } from '@/api/robot';
    import { dexWalletsApprove } from '@/api/markets'
    // 组件
    import robotForm from '@/components/robotForm.vue';
    export default {
        components: {
            robotForm,
        },
        props: ['web', 'web_type', 'yenUnit1', 'yenUnit2', 'closingRobotList', 'runRobots'],
        data() {
            return {
                robotTabCex: localStorage.getItem('robotTabCex') || 0,
                stopRobotType: '',//停止的机器人type

				restartLoading: null,//机器人是否正在重启
				resetCountdownTime: 10,//重启倒计时
            }
        },
        computed: {
            ...mapGetters(['account', 'cexRobotList1', 'cexRobotList2', 'cexRobotList4', 'robotListDex', 'robotListNft', 'isMobile']),
            cexRobotList1Sort(){
                if(this.web_type==0&&this.runRobots&&this.runRobots.length>0){
                    return this.sortRobotsInGroup(this.cexRobotList1,this.runRobots)
                }else{
                    return this.cexRobotList1
                }
            },
            cexRobotList2Sort(){
                if(this.web_type==0&&this.runRobots&&this.runRobots.length>0){
                    return this.sortRobotsInGroup(this.cexRobotList2,this.runRobots)
                }else{
                    return this.cexRobotList2
                }
            },
            cexRobotList4Sort(){
                if(this.web_type==0&&this.runRobots&&this.runRobots.length>0){
                    return this.sortRobotsInGroup(this.cexRobotList4,this.runRobots)
                }else{
                    return this.cexRobotList4
                }
            },
            
            robotListDexSort(){
                if(this.web_type==1&&this.runRobots&&this.runRobots.length>0){
                    return this.sortRobotsInGroup(this.robotListDex,this.runRobots)
                }else{
                    return this.robotListDex
                }
            },
            robotListNftSort(){
                if(this.web_type==2&&this.runRobots&&this.runRobots.length>0){
                    return this.sortRobotsInGroup(this.robotListNft,this.runRobots)
                }else{
                    return this.robotListNft
                }
            },
        },
        methods: {
            // 滚动到对应机器人
            ScrollToRobot(robotType) {
                var robot = document.getElementById(robotType);
                robot?.scrollIntoView(false)
            },
            // 修改序列方案列表
            setPriceSequenceList(priceSequenceList){
                this.$nextTick(() => {
                    // 画线/dex画线 修改序列
                    if(this.web_type==0&&this.$refs.draw){
                        this.$refs.draw[0].setPriceSequenceList(priceSequenceList)
                    }else if(this.web_type==1&&this.$refs.shua_dex){
                        this.$refs.shua_dex[0].setPriceSequenceList(priceSequenceList)
                    }
                })
            },
            // 修改钱包分组列表
            setWalletGroupList(walletGroupList){
                this.$nextTick(() => {
                    this.robotListDex.forEach(item=>{
                        // 除搬砖以外都有
                        if(item.robotType!='banzhuan'&&this.$refs[item.robotType]){
                            this.$refs[item.robotType][0].setWalletGroupList(walletGroupList)
                        }
                    })
                })
            },
            // 机器人排序
            sortRobotsInGroup(robotList, runRobots) {
                const activeRobots = [];
                const inactiveRobots = [];

                robotList.forEach(robot => {
                    if (runRobots.includes(robot.robotType)) {
                        activeRobots.push(robot);
                    } else {
                        inactiveRobots.push(robot);
                    }
                });

                // 返回重新排序后的本组机器人列表
                return [...activeRobots, ...inactiveRobots];
            },
            // 清空机器人原数据
            robotDataToNull() {
                if (this.web_type == 0) {//cex
                    this.cexRobotList1.forEach(item => {
                        this.$refs[item.robotType][0].paramToNull()
                    })
                    this.cexRobotList2.forEach(item => {
                        this.$refs[item.robotType][0].paramToNull()
                    })
                    this.cexRobotList4.forEach(item => {
                        this.$refs[item.robotType][0].paramToNull()
                    })
                } else if (this.web_type == 1) {//dex
                    this.robotListDex.forEach(item => {
                        this.$refs[item.robotType][0].paramToNull()
                    })
                } else if (this.web_type == 2) {//nft
                    this.robotListNft.forEach(item => {
                        this.$refs[item.robotType][0].paramToNull()
                    })
                }
            },
            // cex选择机器人等级
            selectTab(tab) {
                localStorage.setItem('robotTabCex', tab)
                this.robotTabCex = tab
            },
            // param数据初始化 传递param给组件
            paramInit(item) {
                this.$nextTick(() => {
                    let robotFormRefs = this.$refs[item.robotType]
                    if (robotFormRefs) {
                        if (
                            (item.robotType == 'hugevol' && item.sub_type == 'asks') ||
                            (item.robotType == 'kongpan' && item.sub_type == 'rebalance')
                        ) {
                            // 传入默认单选项
                            robotFormRefs[0].paramInit(item.state, item.param, 2);
                        } else {
                            robotFormRefs[0].paramInit(item.state, item.param);
                        }
                    }
                });
            },
            // 科学计数法格式化
            scientificNotationFormat(num) {
                return scientificNotationFormat(num)
            },
            // 打开机器人提示弹窗
            openTip(robotType) {
                this.$emit('openTip', robotType)
            },
            // 打开交易所
            openPop(robotType, data) {
                this.$emit('openPop', data[0], robotType)
            },
            handleSelect(data, sonFormNum, sonPopRobotType) {
                if (this.$refs[sonPopRobotType]) {
                    this.$refs[sonPopRobotType][0].handleSelect(data, sonFormNum)
                }
            },

            // 停止
            handleStop(robotType, data) {
                this.stopRobotType = robotType
                this.$confirm(this.$t('tip.stopRobot'), this.$t('tip.tips'), {
                    confirmButtonText: this.$t('common.confirm2'),
                    cancelButtonText: this.$t('common.back'),
                    center: true,
                    // showClose: false,
                    customClass: 'deleteOk',
                }).then(() => {
                    var param = data[0];
                    var subTypeSelect = data[1];
                    var groupId = data[2];

                    var { type, sub_type } = typeFormat(robotType, subTypeSelect)

                    var currency
                    if (this.web != 'opensea') {
                        currency = this.yenUnit1 + '_' + this.yenUnit2;
                    } else {
                        currency = this.yenUnit1;
                    }
                    this.setRobot(param, 0, type, sub_type, currency, 0, groupId)
                }).catch(() => {
                    // 取消
                });
            },
            // 启动
            handleConfirm(robotType, data) {
                var param = data[0];
                var subTypeSelect = data[1];
                var isRestart = data[2];
                var groupId = data[3];
                
                // console.log('param',param,subTypeSelect)
                var { type, sub_type } = typeFormat(robotType, subTypeSelect)

                var currency
                if (this.web != 'opensea') {
                    currency = this.yenUnit1 + '_' + this.yenUnit2;
                } else {
                    currency = this.yenUnit1;
                }

                // 判断开启的机器人是否在关闭倒计时机器人中
                if (this.closingRobotList.indexOf(robotType) >= 0) {
                    // 正在关闭中，请稍后..
                    this.$message({
                        message: this.$t('tip.closingErr'),
                        center: true,
                        type: 'error',
                        customClass: 'shotMsg',
                    });
                } else {
                    if(isRestart=='isRestart'){// 重启-先停止再启动
                        this.setRobot(param, 0, type, sub_type, currency, isRestart, groupId)
                    }
                    else{// 启动
                        this.setRobot(param, 1, type, sub_type, currency, 0, groupId)
                    }
                }
            },
            // 仅保存
            handleOnlySave(robotType, data) {
                // this.$confirm(this.$t('tip.saveRobot'), this.$t('tip.tips'), {
                //     confirmButtonText: this.$t('common.confirm2'),
                //     cancelButtonText: this.$t('common.back'),
                //     center: true,
                //     showClose: false,
                //     customClass: 'deleteOk',
                // }).then(() => {
                    var param = data[0];
                    var subTypeSelect = data[1];
                    var state = data[2];
                    var groupId = data[3];

                    var { type, sub_type } = typeFormat(robotType, subTypeSelect)

                    var currency
                    if (this.web != 'opensea') {
                        currency = this.yenUnit1 + '_' + this.yenUnit2;
                    } else {
                        currency = this.yenUnit1;
                    }
                    this.setRobot(param, state, type, sub_type, currency, 'onlySave', groupId)
                // }).catch(() => {
                //     // 取消
                // });
            },
            // 修改机器人参数请求
            setRobot(param, state, type, sub_type, currency, isRestart, groupId, approve_type) {
				// 判断是否是需要重启,如果不是直接修改参数
				if (isRestart == 'isRestart') {
					// 触发重启过程中强制禁止操作方法
					this.restartLoading = Loading.service({
						text: this.$t('tip.restart', { time: this.resetCountdownTime ? '(' + this.resetCountdownTime + ')' : '' }),
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)',
						customClass: 'apploading',
					});
				}
                setRobot({
                    web: this.web,
                    currency,
                    account: this.account,
                    type: type,
                    sub_type: sub_type,
                    param: param,
                    state,
                    approve_type: approve_type == 'again' ? '0' : '1'
                }).then(res => {
                    if (res.code == 2107 || (res.code == 2109 && res.type == 'now')) {
                        // 未授权 提示授权中。。
                        this.$message({
                            message: this.$t('tip.processing'),
                            type: 'success',
                            center: true,
                            customClass: 'shotMsg',
                        });
                        // 授权代币请求
                        dexWalletsApprove({
                            web: this.web,
                            account: this.account,
                            approve_token: res.data.replace(',', '_'),//代币
                            is_master_approve: 2,
                            group_id: groupId,
                        }).then(re => {
                            if (re.code == 2172 && re.type == 'confirm') {
                                // 分拨授权
                                this.$emit('openSubTransfer', {
                                    address_list: re.data.address_list,
                                    id_list: re.data.id_list,
                                    chain: re.data.chain,
                                    token: re.data.gas_token,
                                    approve_token: re.data.approve_token,
                                    transferWeb: this.web,
                                    transferExchange: this.exchange,
                                })
                            } else if (re.code == 2172 && re.type == 'ignore') {
                                // 忽略，重新操作
                                this.setRobot(param, state, type, sub_type, currency, isRestart, groupId, 'again')
                            } else {
                                // 授权成功
                                this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
                                    confirmButtonText: this.$t('common.confirm'),//'继续操作',
                                    // showConfirmButton:false,
                                    center: true,
                                    // showClose: false,
                                    customClass: 'deleteOk',
                                    // cancelButtonText: this.$t('common.back'),
                                    showCancelButton: false,
                                })
                            }
                        })
                    }
                    else if (res.code == 2109 && res.type == 'ignore') {
                        // 忽略，重新操作
                        this.setRobot(param, state, type, sub_type, currency, isRestart, groupId, 'again')
                    }
                    else if (res.code == 2109 && res.type == 'confirm') {
                        // 分拨授权
                        this.$emit('openSubTransfer', {
                            address_list: res.data.address_list,
                            id_list: res.data.id_list,
                            chain: res.data.chain,
                            token: res.data.gas_token,
                            approve_token: res.data.approve_token,
                            transferWeb: this.web,
                            transferExchange: this.exchange,
                        })
                    }
                    else {
						// 判断是否是需要重启
						if (isRestart == 'isRestart') {
							this.resetCountdownTime = 10
							// 10s后重启-倒计时
							let resetCountdown = setInterval(() => {
								if (this.resetCountdownTime > 0) {
									this.resetCountdownTime--
									this.restartLoading.setText(this.$t('tip.restart', { time: this.resetCountdownTime ? '(' + this.resetCountdownTime + ')' : '' }))
								} else {
									clearTimeout(resetCountdown)
								}
							}, 1000)
							// 10s后重启
							let robotTimeReset = setTimeout(() => {//重启
                                this.setRobot(param, 1, type, sub_type, currency, 0, groupId)
							}, 10000)
							this.$once('hook:beforeDestroy', function () {
								clearInterval(resetCountdown)
								clearTimeout(robotTimeReset)
							});
						}
                        else if(isRestart == 'onlySave'){// 仅保存
                            this.$message({
                                message: this.$t('tip.saveSuc'),
                                center: true,
                                type: 'success',
                                customClass: 'shotMsg',
                            });
                        }
                        else {
                            if (state == 1) {
                                // 不需要重启或者是重启已完成，关闭重启过程中强制禁止操作方法，
                                this.restartLoading?.close();
                                // 启动--成功
                                this.$message({
                                    message: this.$t('tip.startSuc'),
                                    center: true,
                                    type: 'success',
                                    customClass: 'shotMsg',
                                });
                                
                                // 如果启动，启动成功后二次确认是否需要配置报警系统
                                this.$emit('openAlarmTip')
                            } else {
                                // 停止--成功
                                this.$message({
                                    message: this.$t('tip.stopSuc'),
                                    center: true,
                                    type: 'success',
                                    customClass: 'shotMsg',
                                });

                                // 如果停止成功 则提示10S倒计时自动关闭，并加入closingRobot
                                this.$emit('updateClosingRobot', this.stopRobotType)
                            }
                            this.$emit('getRobotList', 'not')
                        }
                    }
                })
            },
        },
    }
</script>
<style scoped="scoped" lang="stylus">
    @import './robotBox.styl';
</style>